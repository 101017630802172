@import "src/styles/Buttons.module.scss";

.parent {
    height: 100%;
    padding: 1rem;
}

.passwordMeter {
    width: 100%;
}

.group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: bold;
}
