:root {
    --background: #fff;
    --text: #000;
    --sidebar: #8886ff;
    --highlight-text: rgb(41, 41, 41);
}

[data-theme="dark"] {
    --background: #181818;
    --text: #fff;
    --sidebar: #31009b;
    --highlight-text: rgb(196, 196, 196);
}

.slide-pane__header {
    background: var(--background) !important;
    color: var(--text);
}

html,
body,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
}

body {
    margin: 0;
    font-family: Inter, sans-serif;
    background-color: var(--background);
    color: var(--text);
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

input {
    border: none;
    background: rgba(185, 185, 185, 0.507);
    padding: .5rem;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 1.5rem;
    font-family: Inter, sans-serif;

    color: var(--text);

    margin-top: 1rem;
}

input:focus {
    outline: none;
}

textarea {
    border: none;
    resize: none;

    overflow-y: auto;
    overflow-x: hidden;
    
    font-size: 1.5rem;
    font-family: Inter, sans-serif;

    background: rgba(185, 185, 185, 0.507);

    color: var(--text);

    margin-top: 1rem;

    padding: 0.5rem;

    box-sizing: border-box;

    border-radius: 10px;

    width: 100%;

    height: 10rem;
}

textarea:focus {
    outline: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #8a8a8a;
    font-size: 1.5rem;
    /* font-weight: bold; */
    font-family: Inter, sans-serif;
}

a {
    text-decoration: none;
}

select {
    border: 0;
    border-bottom: 2px solid black;
    font-size: 1rem;
}

select:focus {
    outline: none;
}

@font-face {
    font-family: Inter;
    font-weight: normal;
    src: url("./assets/Inter/Inter-VariableFont_slnt\,wght.ttf");
}

@font-face {
    font-family: Inter;
    font-weight: bold;
    src: url("./assets/Inter/static/Inter-Bold.ttf");
}
