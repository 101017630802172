.parent {
    height: 100%;
    width: 100%;
    background-image: url("../assets/blue-splash.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: multiply;
    background-color: rgb(146, 146, 146);

    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    color: white;

    text-align: center;

    .headliner {
        font-size: 7rem;
        font-weight: bold;
        margin: 0;
    }

    .subtitle {
        font-size: 2rem;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
}

.features {
    display: flex;
    flex-direction: column;

    .item {
        display: flex;
        align-items: center;

        .text {
            margin-left: 0.5rem;
            font-weight: bold;
            font-size: 1.5rem;
        }
    }
}

.bigButton {
    width: 20rem;
    height: 5rem;
    border-radius: 500px;
    background-color: #8886ff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 2rem;
    border: none;
    box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
    color: white;
    margin-top: 3rem;
    cursor: pointer;
}

.metaText {
    font-size: 1.5rem;

    .loginText {
        color: #9d9bff;
        font-weight: bold;
        text-decoration: underline;
    }
}
