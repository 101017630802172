.tags {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}

input {
    width: 100%;
}

.deleteButton {
    color: rgb(255, 74, 74);
    display: flex;
    align-items: center;
    cursor: pointer;
}