@import "src/styles/Buttons.module.scss";

.parent {
    height: 100%;
    padding: 1rem;
}

.settingGroup {
    display: flex;
    align-items: center;
    
    margin-top: 1rem;

    .desc {
        margin: 0;
        margin-left: .25rem;
        font-weight: bold;
        font-size: 1.5rem;
        word-break: break-all;
        white-space: pre-wrap;
    }
}

.tags {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}

.backButton {
    color: rgb(104, 104, 104);
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: .25rem;
    cursor: pointer;
}