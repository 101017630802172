.parent {
    height: 100%;
    width: 20rem;
    background-color: var(--sidebar);
    padding: 1rem;
    box-sizing: border-box;

    .items {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;

        .item {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .color {
                border-radius: 50%;
                min-width: 15px;
                min-height: 15px;
                width: 15px;
                height: 15px;
            }

            .text {
                width: 100%;
                height: 2rem;

                display: flex;
                align-items: center;

                cursor: pointer;

                p {
                    margin: 0;
                }
            }

            margin-top: 0.5rem;

            border-radius: 10px;
            padding: 0.5rem;
            box-sizing: border-box;

            &:hover {
                background-color: rgba(255, 255, 255, 0.185);
            }
        }
    }
}

.cog {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    margin-left: 0.5rem;

    &:hover {
        cursor: pointer;
        background-color: #cccccc;
        border-radius: 10px;
    }
}

.prefs {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    position: fixed;
    bottom: 0;
    left: 0;

    cursor: pointer;
    padding: 1rem;

    user-select: none;

    color: var(--text);

    &:hover {
        color: var(--highlight-text);
    }
}
