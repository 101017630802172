.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: 500px;
    width: fit-content;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    user-select: none;

    &:hover {
        cursor: pointer;
    }
}

.count {
    color: #6D6D6D;
    margin-left: .25rem;
}

.editingContainer {
    @extend .container;

    height: fit-content !important;
    margin: 0;
    font-size: 1rem;
}

.cog {
    display: flex;
    align-items: center;
    padding: .25rem;
    margin-left: .5rem;

    &:hover {
        cursor: pointer;
        background-color: #cccccc;
        border-radius: 10px;
    }
}