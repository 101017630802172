.container {
    min-height: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;

    border-radius: 10px;

    box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);

    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        height: 20px;
        width: 20px;
        color: white;
    }
 
    border: 2px solid #8886ff;

    cursor: pointer;
}

.sel {
    background-color: #8886ff;
}
