
.objectiveButton {
    width: fit-content;
    padding: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: white;
    background-color: #0249ff;

    border-radius: 10px;

    border: none;

    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.16);

    margin-top: 1rem;

    cursor: pointer;

    display: flex;
    align-items: center;
    gap: .5rem;

    transition-duration: .15s;

    &:hover {
        background-color: #2361ff;
    }

    &:active {
        background-color: #2352ca;
    }
}

.objectiveParent {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.destructiveButton {
    @extend .objectiveButton;

    background-color: transparent;

    color: red;
    border: 2px solid red;

    &:hover {
        background-color: red;
        color: white;
    }

    &:active {
        background-color: #cc3838;
        border: 2px solid #cc3838;
        color: white;
    }
}