.parent {
    height: 100%;
    padding: 1rem;
    overflow-y: auto;
}

.tags {
    gap: 0.5rem;
    display: flex;
    overflow-x: auto;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.newTaskButton {
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
    min-height: 4rem;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    border-radius: 500px;
    background-color: #0249ff;
    font-weight: bold;
    color: white;
    border: none;
    font-size: 1.25rem;
    font-family: Inter, sans-serif;
    box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2rem;

    transition-duration: .15s;

    &:hover {
        background-color: #2361ff;
    }

    &:active {
        background-color: #2352ca;
    }

}

.tagsParent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sectionTitle {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.slideover {
    background-color: var(--background) !important;
}

.prefsButton {
    @extend .newTaskButton;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    height: 3rem;
    width: 3rem;
    min-height: 3rem;
    min-width: 3rem;

    // height: 3rem;
    // width: 3rem;
    background: rgb(226, 226, 226);
    box-shadow: none;

    color: black;

    font-size: 1.5rem;

    padding: 0;

    @media only screen and (min-width: 800px) {
        display: none;
    }
}
